@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

.ch-logo {
  width: 150px;
  height: 100px;
}

.bg {
  background-color: black;
}

.name {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: 'Kanit', sans-serif;;
  font-weight: 700;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
  margin-top: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: space-between !important;
  flex-direction: row;
  margin: none !important;
}

.nav-info{
  font-family: 'Kanit', sans-serif;;
  font-weight: 700;
    font-size: 18px !important;
    line-height: normal;
    color: white !important;
}


.jelly {
  width: 300px;
  height: 430px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  border: 3px solid transparent;
  background-image: linear-gradient(to right, #330082, #5a0075);
  animation: morphing 10s infinite;
  overflow: hidden;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
}

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }

  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }

  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }

  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}

.about-me {
  color: white;
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
}

.row-fix {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.projects-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

p {
  margin-bottom: 0px !important;
}

.projects-title {
  margin-top: 50px;
  color: white;
  font-family: 'Kanit', sans-serif;
  font-weight: 800;
  font-size: 30px;
}

.projects {
  color: white;
  display: flex;
  align-items: flex-start;
  font-family: 'Montserrat', sans-serif;
  margin-top: 30px !important;

}

.projects-subtitle {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
}

.projects-desc {
  margin-top: 30px;
  padding-right: 20px;
}


:root {
  --bs-link-color: #ffffff !important;
  --bs-link-hover-color: #ffffff !important;
}

.animated-circles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  animation: vawe 10s ease-in-out infinite;
}

.row {
   --bs-gutter-x: 0;
}

@media (max-width: 768px) {
  .circle {
    width: 30px;
    height: 30px;
    left: 30%;
    top: 30%;
  }

  .row-fix {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
  }

  .projects-title {
    margin-top: 50px;
    color: white;
    font-family: 'Kanit', sans-serif;;
    font-weight: 800;
    font-size: 40px;
  }

  .projects-subtitle {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    margin-bottom: 30px;
  }

  .about-me {
    color: white;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
  }

  .projects {
    color: white;
    display: flex;
    align-items: flex-start;
    font-family: 'Montserrat', sans-serif;
    margin-top: 30px !important;
    flex-direction: column;
    flex-wrap: nowrap;

  }

  .projects-desc {
    margin-top: 30px;
    padding-right: 20px;
    font-size: 18px;
    margin-bottom: 20px !important;
  }

  .skills-desc {
    margin-top: 30px;
    padding-right: 20px;
    font-size: 18px;
    margin-bottom: 20px !important;
    width: 200px;
  }


  .name {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: white;
    font-family: 'Kanit', sans-serif;;
    font-weight: 700;
  }


}

@media (max-width: 480px) {
  .circle {
    width: 20px;
    height: 20px;
    left: 20%;
    top: 20%;
  }

  .about-me {
    color: white;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
  }


  .animated-circles {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 100%;
    z-index: -1;
  }

  .projects {
    color: white;
    display: flex;
    align-items: flex-start;
    font-family: 'Montserrat', sans-serif;
    margin-top: 30px !important;
    flex-direction: column;
    flex-wrap: nowrap;

  }

  .skills {
    color: white;
    display: flex;
    align-items: flex-start;
    font-family: 'Montserrat', sans-serif;
    margin-top: 30px !important;
    flex-direction: column;
    flex-wrap: nowrap;

  }

  .name {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: white;
    font-family: 'Kanit', sans-serif;;
    font-weight: 700;
    font-size: 18px;
  }

  .container {
    width: 600px !important;
  }

  .row-fix {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
  }


}


@keyframes vawe {
  20% {
    border-radius: 45% 55% 62% 38% / 53% 51% 49% 47%;
  }

  40% {
    border-radius: 45% 55% 49% 51% / 36% 51% 49% 64%;
  }

  60% {
    border-radius: 60% 40% 57% 43% / 47% 62% 38% 53%;
  }

  80% {
    border-radius: 60% 40% 32% 68% / 38% 36% 64% 62%;
  }
}


.container {
  position: relative;
  z-index: 1 !important;
}

.skills {
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  margin-top: 30px !important;
}

.footer {
  max-height: 200px;
  display: flex;
  justify-content: space-between;
  gap: 39px;
  margin-top: 50px !important;
  margin-bottom: 50px;
  /* padding-left: 50px !important;
  padding-right: 50px !important; */
}

.paper {
  max-width: 50px;
}

.github {
  max-width: 50px;
}

.linkedin {
  max-width: 50px;
}

.logo{
  width: 30px;
  height: 30px;
}

.sql-logo{
  width: 30px;
  height: 30px;
}

.circle-logo{
  width: 30px;
  border-radius: 50%;

}

.skills-logo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.info{
  font-family: 'Kanit', sans-serif;
  color: white;
}
